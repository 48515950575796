import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo_hyundai from "../assets/01_Home/Icon_Hyundai.svg";
import logo_searh from "../assets/01_Home/Icon_Search.svg";
import icon_toggle from "../assets/01_Home/Burger.svg";
import icon_subir from "../assets/01_Home/Icon_subir.svg";
import icon_close from "../assets/01_Home/Close.svg";
//POSVENTA
import garantia from "../assets/02_Hover Nav bar/Posventa/Garantia.webp";
import campania from "../assets/02_Hover Nav bar/Posventa/Campañas.webp";
import mantenimiento from "../assets/02_Hover Nav bar/Posventa/Mantenimiento.webp";
import repuestos from "../assets/02_Hover Nav bar/Posventa/Repuestos.webp";
//WHO WE
import hyundai from "../assets/02_Hover Nav bar/Quienes somos/Hyundai.webp";
import blog from "../assets/02_Hover Nav bar/Quienes somos/Blog.webp";
import contacto from "../assets/02_Hover Nav bar/Quienes somos/PQR.webp";
import astara from "../assets/02_Hover Nav bar/Quienes somos/Astara.webp";
import down from "../assets/01_Home/Hide.svg";
import { vehiclesNavBar } from "../components/conexion/vehicles.conexion";
import { ReservaValidate } from "../components/conexion/home.conexion";
/*eslint-disable */

declare const window: any;

interface MenuLaoutsProps {
  className?: string; // className es opcional
  active?: string;
  setVisibleHeight?: any;
  visibleHeight?: any;
}

const MenuLayouts: React.FC<MenuLaoutsProps> = ({
  className,
  active,
  setVisibleHeight,
  visibleHeight,
}) => {
  const [menuHover, setMenuHover] = useState("auto");
  const [menuHeight, setMenuHeight] = useState("auto");
  const [isHovered, setIsHovered] = useState(false);
  const [activeReserva, setActiveReserva] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const [vehiculos, setVehiculos]: any = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0); // Nueva variable de estado
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    vehiclesNavBar().then(function (res: any) {
      let data: any = res.data.data;
      setVehiculos(data);
    });
  }, []);

  useEffect(() => {
    ReservaValidate().then(function (res: any) {
      setActiveReserva(res?.data?.data?.reserve_active);
    });
  }, []);

  const handleRefresh = (e: any) => {
    if (location.pathname === "/vehiculos") {
      e.preventDefault();
      navigate(0);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (divRef.current) {
        const rect = divRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Calcula la cantidad de píxeles visibles
        const visible =
          Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
        //console.log(Math.max(0, visible));

        setVisibleHeight(Math.max(0, visible) - window.scrollY); // Evita valores negativos
      }
      setScrollPosition(window.scrollY);
    };

    // Escucha de eventos scroll y resize
    window.addEventListener("scroll", handleVisibilityChange);
    window.addEventListener("resize", handleVisibilityChange);

    // Inicializa la visibilidad
    handleVisibilityChange();

    return () => {
      // Limpia los event listeners
      window.removeEventListener("scroll", handleVisibilityChange);
      window.removeEventListener("resize", handleVisibilityChange);
    };
  }, [setVisibleHeight]);

  const handleMouseEnter = (item: any) => {
    setMenuHover(item);
    if (!isHovered) {
      setMenuHeight("521px");
      setIsHovered(true);
      document.body.classList.add("active");
      const element = document.querySelector(`.${className}`);
      if (element) {
        element.classList.add("active");
      }
    }
  };

  const handleMouseLeave = () => {
    if (isHovered) {
      setMenuHeight("auto");
      setMenuHover("");
      document.body.classList.remove("active");
      setTimeout(() => {
        setIsHovered(false);
        const element = document.querySelector(`.${className}`);
        if (element) {
          element.classList.remove("active");
        }
      }, 100); // Duración de la transición
    }
  };

  const handlesendMenuDataLayer = (main: any, category: any) => {
    const dataLayerEvent: any = {
      event: "menu_click",
      comp_page: {
        position: "top menu",
        main_category: main,
        selected_category: category,
      },
    };

    // Empujar al dataLayer
    if (window.dataLayer) {
      window.dataLayer.push(dataLayerEvent);
    }
  };

  return (
    <div
      className={`menu-layout ${isHovered ? "expanded" : ""}`}
      style={
        (active === "vehiculo" || active === "posventa") && visibleHeight <= 0
          ? { position: "absolute", opacity: 0 }
          : {}
      }
      onMouseLeave={handleMouseLeave}
      ref={divRef}
    >
      <div className="content-menu-layout">
        <Link className="logo-menu-layout" to="/">
          <img src={logo_hyundai} alt="Hyundai Logo" />
        </Link>
        <div
          className={`toggle-menu-layout ${isHovered ? "active" : ""}`}
          onClick={() => {
            setIsHovered(!isHovered), setMenuHover("auto");
          }}
        >
          <img src={isHovered ? icon_close : icon_toggle} alt="Close" />
        </div>
        <div className="items-menu-layout">
          <Link
            className={`item-menu-layout ${
              menuHover === "vehiculo" || active === "vehiculo" ? "active" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("vehiculo")}
            to="/vehiculos"
            onClick={() => handlesendMenuDataLayer("Vehículos", null)}
          >
            Vehículos
          </Link>
          <Link
            className={`item-menu-layout ${
              menuHover === "posventa" || active === "posventa" ? "active" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("posventa")}
            to="/posventa"
            onClick={() => handlesendMenuDataLayer("Posventa", null)}
          >
            Posventa
          </Link>
          <Link
            className={`item-menu-layout ${
              menuHover === "concesionario" || active === "concesionario"
                ? "active"
                : ""
            }`}
            //onMouseEnter={() => handleMouseEnter("concesionario")}
            onMouseEnter={handleMouseLeave}
            to="/concesionarios"
            onClick={() => handlesendMenuDataLayer("Concesionarios", null)}
          >
            Concesionarios
          </Link>
          <Link
            to={"/quienes-somos"}
            className={`item-menu-layout ${
              menuHover === "somos" || active === "somos" ? "active" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("somos")}
            onClick={() => handlesendMenuDataLayer("Quiénes somos", null)}
          >
            Quiénes somos
          </Link>
          {activeReserva ? <a
            href="https://reservas.hyundaicolombia.co/"
            target="_blank"
            className={`item-menu-layout ${
              menuHover === "reserva" ? "active" : ""
            }`}
            //onMouseEnter={() => handleMouseEnter("reserva")}
            onMouseEnter={handleMouseLeave}
            onClick={() => handlesendMenuDataLayer("Reserva ya", null)}
          >
            Reserva ya
          </a> : null}
        </div>
        <div className="searh-menu-layout">
          <img src={logo_searh} alt="Search Logo" />
        </div>
      </div>
      <div
        className={`vehiculos-menu-layout ${
          menuHover === "vehiculo" ? "active" : ""
        }`}
      >
        <div className="vehiculos-content-menu-layout">
          {vehiculos
            .sort((a: any, b: any) => a.order - b.order)
            .map((item: any, index: number) => (
              <Link
                key={index}
                to={`/vehiculos/${item?.slug}`}
                className="vehiculo-content-menu-layout"
                style={{
                  backgroundImage: `URL(https://manager.hyundaicolombia.co/${item?.thumbnail?.url})`,
                }}
                onClick={() =>
                  handlesendMenuDataLayer("Vehículos", item?.title)
                }
              >
                <h2>{item?.title}</h2>
                <div className="hover-vehiculo-content-menu-layout"></div>
              </Link>
            ))}
        </div>
        <div className="categories-content-menu-layout">
          <Link
            to="/vehiculos"
            className="category-content-menu-layout"
            onClick={handleRefresh}
          >
            Todos los vehículos
          </Link>
          {/* <div className="category-content-menu-layout">Fichas técnicas</div> */}
          <Link
            to="/cita-taller"
            className="category-content-menu-layout"
            onClick={() =>
              handlesendMenuDataLayer("Vehículos", "Agenda tu Cita Taller")
            }
          >
            Agenda tu Cita Taller
          </Link>
          <a
            href="https://hyundaicolombia.co/interactive-car-studio"
            target="_blank"
            className="category-content-menu-layout"
            onClick={() =>
              handlesendMenuDataLayer(
                "Vehículos",
                "Experiencia interactiva 360°"
              )
            }
          >
            Experiencia interactiva 360°
          </a>
        </div>

        <div className="down">
          <img src={down} alt="Down" />
        </div>
      </div>
      <div
        className={`posventas-menu-layout ${
          menuHover === "posventa" ? "active" : ""
        }`}
      >
        <div className="posventas-content-menu-layout">
          <Link
            to={"/posventa/garantia"}
            className="posventa-content-menu-layout"
            style={{ backgroundImage: `URL(${garantia})` }}
            onClick={() => handlesendMenuDataLayer("Posventa", "Garantía")}
          >
            <h2>Garantía</h2>
            <div className="hover-posventa-content-menu-layout"></div>
          </Link>
          <Link
            to={"/posventa/campana"}
            className="posventa-content-menu-layout"
            style={{ backgroundImage: `URL(${campania})` }}
            onClick={() =>
              handlesendMenuDataLayer("Posventa", "Campañas de seguridad")
            }
          >
            <h2>Campañas de seguridad</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
          <Link
            to={"/posventa/mantenimiento"}
            className="posventa-content-menu-layout"
            style={{ backgroundImage: `URL(${mantenimiento})` }}
            onClick={() => handlesendMenuDataLayer("Posventa", "Mantenimiento")}
          >
            <h2>Mantenimiento</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
          <Link
            to={"/posventa/repuestos-genuinos"}
            className="posventa-content-menu-layout"
            style={{ backgroundImage: `URL(${repuestos})` }}
            onClick={() =>
              handlesendMenuDataLayer("Posventa", "Repuestos genuinos")
            }
          >
            <h2>Repuestos genuinos</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
        </div>
        <div className="categories-content-menu-layout">
          <Link
            to={"/posventa/garantia"}
            className="category-content-menu-layout"
            onClick={() =>
              handlesendMenuDataLayer("Posventa", "Manuales de garantía")
            }
          >
            Manuales de garantía
          </Link>
          <Link
            to={"/posventa/campana"}
            className="category-content-menu-layout"
            onClick={() =>
              handlesendMenuDataLayer("Posventa", "Campañas disponibles")
            }
          >
            Campañas disponibles
          </Link>
          <Link
            to={"/fichas-seguridad"}
            className="category-content-menu-layout"
            onClick={() =>
              handlesendMenuDataLayer("Posventa", "Agendar Cita Taller")
            }
          >
            Fichas de seguridad
          </Link>
          <Link
            to={"/cita-taller"}
            className="category-content-menu-layout"
            onClick={() =>
              handlesendMenuDataLayer("Posventa", "Agendar Cita Taller")
            }
          >
            Agendar Cita Taller
          </Link>
        </div>
      </div>
      <div
        className={`who-we-menu-layout ${
          menuHover === "somos" ? "active" : ""
        }`}
      >
        <div className="who-we-are-content-menu-layout">
          <Link
            to={"/quienes-somos"}
            className="who-we-content-menu-layout"
            style={{ backgroundImage: `URL(${hyundai})` }}
            onClick={() =>
              handlesendMenuDataLayer("Quiénes somos", "Sobre Hyundai")
            }
          >
            <h2>Sobre Hyundai</h2>
            <div className="hover-who-we-content-menu-layout"></div>
          </Link>
          <Link
            to={"/noticias"}
            className="who-we-content-menu-layout"
            style={{ backgroundImage: `URL(${blog})` }}
            onClick={() =>
              handlesendMenuDataLayer("Quiénes somos", "Blog y novedades")
            }
          >
            <h2>Blog y novedades</h2>
            <div className="hover-who-we-content-menu-layout"></div>
          </Link>
          <Link
            to={"/contactanos"}
            className="who-we-content-menu-layout"
            style={{ backgroundImage: `URL(${contacto})` }}
            onClick={() =>
              handlesendMenuDataLayer("Quiénes somos", "Escríbenos")
            }
          >
            <h2>Escríbenos</h2>
            <div className="hover-who-we-content-menu-layout"></div>
          </Link>
          <Link
            to={"/astara"}
            className="who-we-content-menu-layout"
            style={{ backgroundImage: `URL(${astara})` }}
            onClick={() =>
              handlesendMenuDataLayer("Quiénes somos", "Sobre Astara")
            }
          >
            <h2>Sobre Astara</h2>
            <div className="hover-who-we-content-menu-layout"></div>
          </Link>
        </div>
      </div>
      <div className="content-movil-menu-layout">
        <div className="content-movil-items-menu-layout">
          <div className="content-movil-item-menu-layout">
            <div
              className={`content-movil-item-label-menu-layout ${
                menuHover === "Vehículos" ? "active" : ""
              }`}
              onClick={() => {
                setMenuHover(menuHover !== "Vehículos" ? "Vehículos" : "auto"),
                  handlesendMenuDataLayer("Vehículos", null);
              }}
            >
              Vehículos <img src={icon_subir} alt="subir" />
            </div>
            <div
              className={`content-movil-item-vehiculos-menu-layout ${
                menuHover === "Vehículos" ? "active" : ""
              }`}
            >
              {vehiculos
                .sort((a: any, b: any) => a.order - b.order)
                .map((item: any, index: any) => (
                  <Link
                    key={index}
                    to={`/vehiculos/${item?.slug}`}
                    className="content-movil-item-vehiculo-menu-layout"
                    style={{
                      backgroundImage: `URL(https://manager.hyundaicolombia.co/${item?.thumbnail?.url})`,
                    }}
                    onClick={() =>
                      handlesendMenuDataLayer("Vehículos", item?.title)
                    }
                  >
                    <h2>{item?.title}</h2>
                    <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
                  </Link>
                ))}
              <Link
                className="list-content-movil-item-vehiculo-menu-layout"
                to={"/vehiculos"}
                onClick={() =>
                  handlesendMenuDataLayer("Vehículos", "Todos los vehículos")
                }
              >
                Todos los vehículos
              </Link>
              <Link
                className="list-content-movil-item-vehiculo-menu-layout"
                to={"/vehiculos"}
                onClick={() =>
                  handlesendMenuDataLayer("Vehículos", "Agendar Cita Taller")
                }
              >
                Agendar Cita Taller
              </Link>
              <Link
                className="list-content-movil-item-vehiculo-menu-layout"
                to={"/vehiculos"}
                onClick={() =>
                  handlesendMenuDataLayer("Vehículos", "Comparador")
                }
              >
                Comparador
              </Link>
              <Link
                className="list-content-movil-item-vehiculo-menu-layout"
                to={"/vehiculos"}
                onClick={() =>
                  handlesendMenuDataLayer("Vehículos", "Comparador")
                }
              >
                <p>{/* Explora la experiencia */}</p>
                Comparador
              </Link>
            </div>
          </div>
          <div className="content-movil-item-menu-layout">
            <div
              className={`content-movil-item-label-menu-layout ${
                menuHover === "Posventa" ? "active" : ""
              }`}
              onClick={() => {
                setMenuHover(menuHover !== "Posventa" ? "Posventa" : "auto"),
                  handlesendMenuDataLayer("Posventa", null);
              }}
            >
              Posventa <img src={icon_subir} alt="Subir" />
            </div>
            <div
              className={`content-movil-item-vehiculos-menu-layout ${
                menuHover === "Posventa" ? "active" : ""
              }`}
            >
              <Link
                to={"/posventa/garantia"}
                className="content-movil-item-posventa-menu-layout"
                style={{ backgroundImage: `URL(${garantia})` }}
                onClick={() => handlesendMenuDataLayer("Posventa", "Garantía")}
              >
                <h2>Garantía</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/posventa/campana"}
                className="content-movil-item-posventa-menu-layout"
                style={{ backgroundImage: `URL(${campania})` }}
                onClick={() => handlesendMenuDataLayer("Posventa", "Campañas")}
              >
                <h2>Campañas</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/posventa/mantenimiento"}
                className="content-movil-item-posventa-menu-layout"
                style={{ backgroundImage: `URL(${mantenimiento})` }}
                onClick={() =>
                  handlesendMenuDataLayer("Posventa", "Mantenimiento")
                }
              >
                <h2>Mantenimiento</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/posventa/repuestos-genuinos"}
                className="content-movil-item-posventa-menu-layout"
                style={{ backgroundImage: `URL(${repuestos})` }}
                onClick={() =>
                  handlesendMenuDataLayer("Posventa", "Repuestos genuinos")
                }
              >
                <h2>Repuestos genuinos</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
            </div>
          </div>
          <div className="content-movil-item-menu-layout">
            <Link
              to={"/concesionarios"}
              className="content-movil-item-label-menu-layout"
              onClick={() => {
                setMenuHover("Concesionarios"),
                  handlesendMenuDataLayer("Concesionarios", null);
              }}
            >
              Concesionarios <img src={icon_subir} alt="Subir" />
            </Link>
          </div>

          <div className="content-movil-item-menu-layout">
            <div
              className={`content-movil-item-label-menu-layout ${
                menuHover === "Msomos" ? "active" : ""
              }`}
              onClick={() => {
                setMenuHover(menuHover !== "Msomos" ? "Msomos" : "auto"),
                  handlesendMenuDataLayer("Quiénes somos", null);
              }}
            >
              Quiénes somos <img src={icon_subir} alt="Subir" />
            </div>
            <div
              className={`content-movil-item-vehiculos-menu-layout ${
                menuHover === "Msomos" ? "active" : ""
              }`}
            >
              <Link
                to={"/quienes-somos"}
                className="content-movil-item-posventa-menu-layout"
                style={{ backgroundImage: `URL(${hyundai})` }}
                onClick={() =>
                  handlesendMenuDataLayer("Quiénes somos", "Sobre Hyundai")
                }
              >
                <h2>Sobre Hyundai</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/noticias"}
                className="content-movil-item-posventa-menu-layout"
                style={{ backgroundImage: `URL(${blog})` }}
                onClick={() =>
                  handlesendMenuDataLayer("Quiénes somos", "Blog y novedades")
                }
              >
                <h2>Blog y novedades</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/contactanos"}
                className="content-movil-item-posventa-menu-layout"
                style={{ backgroundImage: `URL(${contacto})` }}
                onClick={() =>
                  handlesendMenuDataLayer("Quiénes somos", "Escríbenos")
                }
              >
                <h2>Escríbenos</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/astara"}
                className="content-movil-item-posventa-menu-layout"
                style={{ backgroundImage: `URL(${astara})` }}
                onClick={() =>
                  handlesendMenuDataLayer("Quiénes somos", "Sobre Astara")
                }
              >
                <h2>Sobre Astara</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
            </div>
          </div>
          <div className="content-movil-item-menu-layout">
            {/* <div
              className="content-movil-item-label-menu-layout"
              onClick={() => {setMenuHover("Reserva ya"), handlesendMenuDataLayer("Reserva ya", null)}}
            >
              Reserva ya <img src={icon_subir} alt="" />
            </div> */}
            {activeReserva ? <a
              href="https://reservas.hyundaicolombia.co/"
              target="_blank"
              className={`content-movil-item-label-menu-layout ${
                menuHover === "reserva" ? "active" : ""
              }`}
              //onMouseEnter={() => handleMouseEnter("reserva")}
              onMouseEnter={handleMouseLeave}
              onClick={() => {
                setMenuHover("Reserva ya"),
                  handlesendMenuDataLayer("Reserva ya", null);
              }}
            >
              Reserva ya
            </a> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuLayouts;
