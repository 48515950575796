import { forwardRef, useEffect, useRef, useState } from "react";

/*eslint-disable */

declare const window: any;

interface VehiculosComponentProps {
  vehiculosData: any;
  setVehiculosSelected: any;
}

const VehiculosComponent: React.FC<VehiculosComponentProps> = ({
  vehiculosData,
  setVehiculosSelected,
}) => {
  const [currentIndex, setCurrentIndex]: any = useState(0); // Inicialmente se muestra la imagen en el centro
  const containerRef: any = useRef<HTMLDivElement>(null); // Referencia al contenedor
  const [scrollPosition, setScrollPosition] = useState(0); // Estado para la posición del scroll

  const handlePrev = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 256; // Desplazar 256px hacia la izquierda
    }
  };

  const handleNext = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 256; // Desplazar 256px hacia la derecha
    }
  };

  // Manejar el evento de scroll para actualizar la posición
  const handleScroll = () => {
    if (containerRef.current) {
      const scrollWidth = containerRef.current.scrollWidth;
      const clientWidth = containerRef.current.clientWidth;
      const scrollLeft = containerRef.current.scrollLeft;

      // Calcular la posición relativa del scroll
      setScrollPosition((scrollLeft / (scrollWidth - clientWidth)) * 100);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener("scroll", handleScroll);

    // Limpiar el evento al desmontar
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="multi-cotizacion-vehiculos-detail-component">
        <div
          className="btn-carrousel-multi-cotizacion-component prev"
          onClick={handlePrev}
        >
          <img src="../assets/01_Home/Icon_Left.svg" alt="Anterior" />
        </div>
        <div
          className="info-multi-cotizacion-vehiculos-detail-component"
          style={vehiculosData?.length < 6 ? { justifyContent: "center" } : {}}
          ref={containerRef} // Asignar la referencia al contenedor
        >
          {vehiculosData?.map((item: any, index: number) => (
            <div
              className={`item-info-multi-cotizacion-vehiculos-detail-component ${
                index === currentIndex ? "active" : ""
              }`}
              onClick={() => {
                setCurrentIndex(index), setVehiculosSelected(item);
              }}
              key={index}
            >
              <div className="title-info-multi-cotizacion-vehiculos-detail-component">
                {item.name}
              </div>
              <img
                className="img-info-multi-cotizacion-vehiculos-detail-component"
                src={`https://manager.hyundaicolombia.co/${item?.versions[0]?.image?.url}`}
                alt={`Imagen del vehículo ${item.name}`}
              />
              <img
                className="sello-info-multi-cotizacion-vehiculos-detail-component"
                src={`https://manager.hyundaicolombia.co/${item?.versions[0]?.security_seal?.url}`}
                alt={`Sello de seguridad de ${item.name}`}
              />
            </div>
          ))}
        </div>
        <div
          className="btn-carrousel-multi-cotizacion-component next"
          onClick={handleNext}
        >
          <img src="../assets/01_Home/Icon_Right.svg" alt="Siguiente" />
        </div>
      </div>
      <div className="scroll-bar-container">
        <div
          className="scroll-bar"
          style={{ width: `${scrollPosition}%` }} // Ajustar el ancho según la posición del scroll
        ></div>
      </div>
    </>
  );
};

export default VehiculosComponent;
