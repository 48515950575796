import React from "react";
import banner from "../../assets/10_POSVENTA/1.webp";

/*eslint-disable */
interface DescriptionComponentProps {
  multicotizadorData: any;
}

declare const window: any;
const DescriptionComponent: React.FC<DescriptionComponentProps> = ({
  multicotizadorData
}) => {
  return (
    <div className="description-multi-cotizacion-component">
      <div className="title-description-multi-cotizacion-component">
        {multicotizadorData[0]?.title}
      </div>
      <div className="text-description-multi-cotizacion-component">
      {multicotizadorData[0]?.description}
      </div>
    </div>
  );
};

export default DescriptionComponent;
