import axios from "axios";

const URL = "https://gateway.hyundaicolombia.co/"

export const VehiclesAll = async () => {
  return await axios.get(URL + "vehicles/all");
};

export const VehiclesOnly = async (vehiculo: any) => {
  // Codifica el nombre del vehículo para evitar problemas con espacios u otros caracteres especiales
  const encodedVehiculo = encodeURIComponent(vehiculo);
  return await axios.post(
    `${URL}vehicles/only`, { encodedVehiculo }
  );
};

export const VehiclesOnlyParent = async (vehiculo: any) => {
  // Codifica el nombre del vehículo para evitar problemas con espacios u otros caracteres especiales
  const encodedVehiculo = encodeURIComponent(vehiculo);

  return await axios.post(
    `${URL}vehicles/only/parent`, {encodedVehiculo}
  );
};

export const ConcesionariosAll = async () => {
  return await axios.get(
    `${URL}vehicles/concesionarios`
  );
};

export const vehiclesNavBar = async () => {
  return await axios.get(`${URL}vehicles/navbar`);
};

export const vehiclesSimulator = async () => {
  return await axios.get(
    `${URL}vehicles/simulator`
  );
};

export const multicotizador = async () => {
  return await axios.get(
    `${URL}vehicles/cotizador`
  );
};

export const fichasSeguridad = async () => {
  return await axios.get(
    `${URL}vehicles/fichas-seguridad`
  );
};

export const sendSimulator = async (data: any) => {
  try {
    // Primer endpoint: obtener el token
    const response = await axios.post(
      "https://8d6cxglsnh.execute-api.us-east-1.amazonaws.com/api/simulator",
      data
    );

    return response.data;
  } catch (error: any) {
    console.error(
      "Error en sendSimulator:",
      error?.response?.data || error.message
    );
    throw new Error(
      error?.response?.data?.message || "Error al procesar la solicitud."
    );
  }
};

export const sendFormQuotes =  async (data: any) => {
  const url = 'https://manager.hyundaicolombia.co/api/form-quotes';
const token = 'e4d1b3b5ed8c917d8e687f9e6f6734b489f70c4ce1bad187a5478253ccc07013071406dbf60fe87aa10b8e4f2c4f47fc54342c1e1da71fa63ad15d3ab97098ffc360cfc4ce170d65fe120a325a907f2ef752ebf2a6692f3611ebc26549ea4bba1993638109d9412f12908780f8a1d8e7b973b717028b07c67114e2360a46984d';

const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${token}`
};

  return await axios.post(url, data, { headers });
}

export const sendFormMultiQuotes =  async (data: any) => {
  const url = 'https://manager.hyundaicolombia.co/api/form-multi-quotes';
const token = 'e4d1b3b5ed8c917d8e687f9e6f6734b489f70c4ce1bad187a5478253ccc07013071406dbf60fe87aa10b8e4f2c4f47fc54342c1e1da71fa63ad15d3ab97098ffc360cfc4ce170d65fe120a325a907f2ef752ebf2a6692f3611ebc26549ea4bba1993638109d9412f12908780f8a1d8e7b973b717028b07c67114e2360a46984d';

const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${token}`
};

  return await axios.post(url, data, { headers });
}

export const sendFormPQRS =  async (data: any) => {
  const url = 'https://manager.hyundaicolombia.co/api/form-pqrs';
const token = 'e4d1b3b5ed8c917d8e687f9e6f6734b489f70c4ce1bad187a5478253ccc07013071406dbf60fe87aa10b8e4f2c4f47fc54342c1e1da71fa63ad15d3ab97098ffc360cfc4ce170d65fe120a325a907f2ef752ebf2a6692f3611ebc26549ea4bba1993638109d9412f12908780f8a1d8e7b973b717028b07c67114e2360a46984d';

const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${token}`
};

  return await axios.post(url, data, { headers });
}
