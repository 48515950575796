import React, { useEffect, useState } from "react";

/* eslint-disable */

interface BannerComponentProps {
  multicotizadorData: any;
}

declare const window: any;

const BannerComponent: React.FC<BannerComponentProps> = ({ multicotizadorData }) => {
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: portrait)");

    // Actualiza el estado basado en la orientación actual
    const updateOrientation = () => {
      setIsPortrait(mediaQuery.matches);
    };

    updateOrientation(); // Inicializa el estado al cargar el componente
    mediaQuery.addEventListener("change", updateOrientation); // Escucha cambios en la orientación

    // Limpia el evento al desmontar el componente
    return () => {
      mediaQuery.removeEventListener("change", updateOrientation);
    };
  }, []);

  return (
    <div
      className="banner-multi-cotizacion-component"
      style={{
        backgroundImage: `url(https://manager.hyundaicolombia.co/${isPortrait ? multicotizadorData[0]?.banner_portrait?.url : multicotizadorData[0]?.banner?.url})`,
      }}
    ></div>
  );
};

export default BannerComponent;
