import { useEffect, useState } from "react";
import icon_whatsapp from "../assets/01_Home/Icon_Whatsapp.svg";
import icon_subir from "../assets/01_Home/Icon_subir.svg";
import icon_close from "../assets/01_Home/Close.svg";
import { ReservaValidate } from "../components/conexion/home.conexion";

/*eslint-disable */

interface BtnFloatingLayoutProps {
  reserva: any;
}

declare const window: any;

const BtnFloatingLayout: React.FC<BtnFloatingLayoutProps> = ({ reserva }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [active, setActive] = useState(false);
  const [showMovilBtn, setShowMovilBtn] = useState(false);

  useEffect(() => {
    ReservaValidate().then(function (res: any) {
      setActive(res?.data?.data?.reserve_active);
    });
  }, []);

  // Mostrar el botón cuando se hace scroll hacia abajo
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const open_chat = () => {
    const dataLayerEvent: any = {
      event: "open_chat",
      comp_page: {
        chat_type: "WhatsApp",
      },
    };

    // Empujar al dataLayer
    if (window.dataLayer) {
      window.dataLayer.push(dataLayerEvent);
    }
  };

  const scrollUp = () => {
    console.log("aqui");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className={`btn-floating-layout ${showMovilBtn ? "active" : ""} `}>
        <a
          href={reserva}
          target="_blank"
          className={`reserva-btn-floating-layout ${active ? "" : "d-none"}`}
        >
          Reserva en línea
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=573013658534&text=Hola%2C%20quiero%20mas%20información"
          target="_blank"
          className="whatsapp-btn-floating-layout"
          onClick={() => open_chat()}
        >
          <img src={icon_whatsapp} alt="Ícono de WhatsApp" />
        </a>
        <div className="up-btn-floating-layout" onClick={() => scrollUp()}>
          <img src={icon_subir} alt="Ícono de subir" />
        </div>
      </div>
      <div
        className={`plus-btn-floating-layout ${showMovilBtn ? "" : "active"}`}
        onClick={() => {
          showMovilBtn ? setShowMovilBtn(false) : setShowMovilBtn(true);
        }}
      >
        <img src={icon_close} alt="Ícono de cerrar" />
      </div>
    </>
  );
};

export default BtnFloatingLayout;
