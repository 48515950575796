import React, { forwardRef, useEffect, useRef, useState } from "react";
import { sendFormMultiQuotes } from "../conexion/vehicles.conexion";

/*eslint-disable */

declare const window: any;

interface CotizacionVehiculosComponentProps {
  vehiculo: any;
}

const concesionariosData: any = [
  {
    CONCESIONARIO: "AG OMEGA",
    DEPARTAMENTO: "BOGOTA",
    CIUDAD: "BOGOTÁ",
    "VITRINA (EXPOSICIÓN)": "Carrera 24",
    COD_VITRINA_EXPOSICION: "a17QC00000b84mpYAA",
    NIT: "9014563301",
    "ID Dealers": "302117",
    "ID sucursales": "905590",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "11 BOGOTA",
    City: "BOGOTÁ, D.C.",
  },
  {
    CONCESIONARIO: "AUTAMA",
    DEPARTAMENTO: "QUINDIO",
    CIUDAD: "ARMENIA",
    "VITRINA (EXPOSICIÓN)": "Armenia",
    COD_VITRINA_EXPOSICION: "a17QC00000b83ieYAA",
    NIT: "9009235085",
    "ID Dealers": "302102",
    "ID sucursales": "905558",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "63 QUINDIO",
    City: "ARMENIA",
  },
  {
    CONCESIONARIO: "AUTOLARTE",
    DEPARTAMENTO: "ANTIOQUIA",
    CIUDAD: "ITAGUI",
    "VITRINA (EXPOSICIÓN)": "Itagui",
    COD_VITRINA_EXPOSICION: "a17QC00002GtNebYAF",
    NIT: "8909000818",
    "ID Dealers": "302707",
    "ID sucursales": "907059",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "05 ANTIOQUIA",
    City: "ITAGUI",
  },
  {
    CONCESIONARIO: "AUTOLARTE",
    DEPARTAMENTO: "ANTIOQUIA",
    CIUDAD: "RIONEGRO",
    "VITRINA (EXPOSICIÓN)": "Rionegro",
    COD_VITRINA_EXPOSICION: "a17QC00002Gt0CdYAJ",
    NIT: "8909000818",
    "ID Dealers": "302707",
    "ID sucursales": "907056",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "05 ANTIOQUIA",
    City: "RIONEGRO",
  },
  {
    CONCESIONARIO: "AUTOMOTOR",
    DEPARTAMENTO: "BOGOTA",
    CIUDAD: "BOGOTÁ",
    "VITRINA (EXPOSICIÓN)": "Calle 235",
    COD_VITRINA_EXPOSICION: "a17QC00000bL2aXYAS",
    NIT: "9009692000",
    "ID Dealers": "302099",
    "ID sucursales": "905594",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "11 BOGOTA",
    City: "BOGOTÁ, D.C.",
  },
  {
    CONCESIONARIO: "AUTOMOTOR",
    DEPARTAMENTO: "ANTIOQUIA",
    CIUDAD: "MEDELLÍN",
    "VITRINA (EXPOSICIÓN)": "Palacé",
    COD_VITRINA_EXPOSICION: "a17QC00000bL2adYAC",
    NIT: "9009692000",
    "ID Dealers": "302099",
    "ID sucursales": "905600",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "05 ANTIOQUIA",
    City: "MEDELLIN",
  },
  /* {
    "CONCESIONARIO": "AUTOMOTOR",
    "DEPARTAMENTO": "ANTIOQUIA",
    "CIUDAD": "MEDELLÍN",
    "VITRINA (EXPOSICIÓN)": "Palacé (Taxis)",
    "COD_VITRINA_EXPOSICION": "a17QC00002M3NIgYAN",
    "NIT": "9009692000",
    "ID Dealers": "302099",
    "ID sucursales": "907072",
    "TIPO DE VEHICULOS": "TAXIS",
    "Department_Code_And_Name": "05 ANTIOQUIA",
    "City": "MEDELLIN"
  }, */
  {
    CONCESIONARIO: "AUTOMOTOR",
    DEPARTAMENTO: "VALLE DEL CAUCA",
    CIUDAD: "CALI",
    "VITRINA (EXPOSICIÓN)": "Santa Monica",
    COD_VITRINA_EXPOSICION: "a17QC00000bL2aZYAS",
    NIT: "9009692000",
    "ID Dealers": "302099",
    "ID sucursales": "905596",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "76 VALLE DEL CAUCA",
    City: "CALI",
  },
  {
    CONCESIONARIO: "AUTONAL",
    DEPARTAMENTO: "BOGOTA",
    CIUDAD: "BOGOTÁ",
    "VITRINA (EXPOSICIÓN)": "La Felicidad Av Boyaca",
    COD_VITRINA_EXPOSICION: "a17QC00001mOwxeYAC",
    NIT: "9017736050",
    "ID Dealers": "302362",
    "ID sucursales": "906434",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "11 BOGOTA",
    City: "BOGOTÁ, D.C.",
  },
  {
    CONCESIONARIO: "AUTONAL",
    DEPARTAMENTO: "META",
    CIUDAD: "VILLAVICENCIO",
    "VITRINA (EXPOSICIÓN)": "Villavicencio",
    COD_VITRINA_EXPOSICION: "a17QC00001mOwxjYAC",
    NIT: "9017736050",
    "ID Dealers": "302362",
    "ID sucursales": "906440",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "50 META",
    City: "VILLAVICENCIO",
  },
  {
    CONCESIONARIO: "AUTONAL",
    DEPARTAMENTO: "BOYACA",
    CIUDAD: "TUNJA",
    "VITRINA (EXPOSICIÓN)": "Tunja",
    COD_VITRINA_EXPOSICION: "a17QC00001mPfInYAK",
    NIT: "9017736050",
    "ID Dealers": "302362",
    "ID sucursales": "906451",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "15 BOYACA",
    City: "TUNJA",
  },
  {
    CONCESIONARIO: "AUTONORTE",
    DEPARTAMENTO: "ATLANTICO",
    CIUDAD: "BARRANQUILLA",
    "VITRINA (EXPOSICIÓN)": "Barranquilla",
    COD_VITRINA_EXPOSICION: "a17QC00000b8409YAA",
    NIT: "8001656941",
    "ID Dealers": "302103",
    "ID sucursales": "905560",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "08 ATLANTICO",
    City: "BARRANQUILLA",
  },
  {
    CONCESIONARIO: "AUTONORTE",
    DEPARTAMENTO: "MAGDALENA",
    CIUDAD: "SANTA MARTA",
    "VITRINA (EXPOSICIÓN)": "Santa Marta",
    COD_VITRINA_EXPOSICION: "a17QC00000b840BYAQ",
    NIT: "8001656941",
    "ID Dealers": "302103",
    "ID sucursales": "905562",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "47 MAGDALENA",
    City: "SANTA MARTA",
  },
  {
    CONCESIONARIO: "AUTONORTE",
    DEPARTAMENTO: "CESAR",
    CIUDAD: "VALLEDUPAR",
    "VITRINA (EXPOSICIÓN)": "Valledupar",
    COD_VITRINA_EXPOSICION: "a17QC00000b840CYAQ",
    NIT: "8001656941",
    "ID Dealers": "302103",
    "ID sucursales": "905563",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "20 CESAR",
    City: "VALLEDUPAR",
  },
  {
    CONCESIONARIO: "AUTONORTE",
    DEPARTAMENTO: "BOLIVAR",
    CIUDAD: "CARTAGENA",
    "VITRINA (EXPOSICIÓN)": "Cartagena",
    COD_VITRINA_EXPOSICION: "a17QC00000b840DYAQ",
    NIT: "8001656941",
    "ID Dealers": "302103",
    "ID sucursales": "905564",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "13 BOLIVAR",
    City: "CARTAGENA",
  },
  {
    CONCESIONARIO: "AUTOPACIFICO",
    DEPARTAMENTO: "VALLE DEL CAUCA",
    CIUDAD: "TULUA",
    "VITRINA (EXPOSICIÓN)": "Tulua",
    COD_VITRINA_EXPOSICION: "a17QC00000r780HYAQ",
    NIT: "8903272824",
    "ID Dealers": "302119",
    "ID sucursales": "906113",
    "TIPO DE VEHICULOS": "PARTICULARES",
    Department_Code_And_Name: "76 VALLE DEL CAUCA",
    City: "TULUA",
  },
  /* {
    "CONCESIONARIO": "AUTOPACIFICO",
    "DEPARTAMENTO": "VALLE DEL CAUCA",
    "CIUDAD": "TULUA",
    "VITRINA_EXPOSICION": "Tulua",
    "COD_VITRINA_EXPOSICION": "a17QC00000r780HYAQ",
    "NIT": "8903272824",
    "ID_Dealers": "302119",
    "ID_sucursales": "906113",
    "TIPO_DE_VEHICULOS": "PARTICULARES",
    "Department_Code_And_Name": "76 VALLE DEL CAUCA",
    "City": "TULUA"
  }, */
  {
    CONCESIONARIO: "AUTOPACIFICO",
    DEPARTAMENTO: "VALLE DEL CAUCA",
    CIUDAD: "PALMIRA",
    VITRINA_EXPOSICION: "Palmira",
    COD_VITRINA_EXPOSICION: "a17QC00000r780IYAQ",
    NIT: "8903272824",
    ID_Dealers: "302119",
    ID_sucursales: "906114",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "76 VALLE DEL CAUCA",
    City: "PALMIRA",
  },
  {
    CONCESIONARIO: "AUTOPACIFICO",
    DEPARTAMENTO: "VALLE DEL CAUCA",
    CIUDAD: "CALI",
    VITRINA_EXPOSICION: "Cali",
    COD_VITRINA_EXPOSICION: "a17QC00000b86d4YAA",
    NIT: "8903272824",
    ID_Dealers: "302119",
    ID_sucursales: "905609",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "76 VALLE DEL CAUCA",
    City: "CALI",
  },
  {
    CONCESIONARIO: "LOS COCHES",
    DEPARTAMENTO: "CALDAS",
    CIUDAD: "MANIZALES",
    VITRINA_EXPOSICION: "Manizales",
    COD_VITRINA_EXPOSICION: "a17QC00002jpk25YAA",
    NIT: "8600526342",
    ID_Dealers: "302104",
    ID_sucursales: "905565",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "17 CALDAS",
    City: "MANIZALES",
  },
  {
    CONCESIONARIO: "LOS COCHES",
    DEPARTAMENTO: "RISARALDA",
    CIUDAD: "PEREIRA",
    VITRINA_EXPOSICION: "Pereira",
    COD_VITRINA_EXPOSICION: "a17QC00002jpk26YAA",
    NIT: "8600526342",
    ID_Dealers: "302104",
    ID_sucursales: "905566",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "66 RISARALDA",
    City: "PEREIRA",
  },
  {
    CONCESIONARIO: "CENTRAL MOTOR",
    DEPARTAMENTO: "SANTANDER",
    CIUDAD: "BUCARAMANGA",
    VITRINA_EXPOSICION: "Bucaramanga",
    COD_VITRINA_EXPOSICION: "a17QC00000b86HmYAI",
    NIT: "8040028327",
    ID_Dealers: "302111",
    ID_sucursales: "905584",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "68 SANTANDER",
    City: "BUCARAMANGA",
  },
  {
    CONCESIONARIO: "DISTRIROBLE",
    DEPARTAMENTO: "CORDOBA",
    CIUDAD: "MONTERÍA",
    VITRINA_EXPOSICION: "Montería",
    COD_VITRINA_EXPOSICION: "a17QC00000b86L6YAI",
    NIT: "8910000178",
    ID_Dealers: "302106",
    ID_sucursales: "905572",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "23 CORDOBA",
    City: "MONTERIA",
  },
  {
    CONCESIONARIO: "FERSAUTOS",
    DEPARTAMENTO: "N. DE SANTANDER",
    CIUDAD: "CÚCUTA",
    VITRINA_EXPOSICION: "Cúcuta",
    COD_VITRINA_EXPOSICION: "a17QC00000bLDDKYA4",
    NIT: "8040027241",
    ID_Dealers: "302105",
    ID_sucursales: "905568",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "54 N. DE SANTANDER",
    City: "CUCUTA",
  },
  {
    CONCESIONARIO: "FERSAUTOS",
    DEPARTAMENTO: "ANTIOQUIA",
    CIUDAD: "MEDELLÍN",
    VITRINA_EXPOSICION: "Guayabal",
    COD_VITRINA_EXPOSICION: "a17QC00000bLDDJYA4",
    NIT: "8040027241",
    ID_Dealers: "302105",
    ID_sucursales: "905567",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "05 ANTIOQUIA",
    City: "MEDELLIN",
  },
  {
    CONCESIONARIO: "LOS COCHES",
    DEPARTAMENTO: "BOGOTA",
    CIUDAD: "BOGOTÁ",
    VITRINA_EXPOSICION: "Calle 26",
    COD_VITRINA_EXPOSICION: "a17QC00000avsn2YAA",
    NIT: "8600526342",
    ID_Dealers: "302082",
    ID_sucursales: "905619",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "11 BOGOTA",
    City: "BOGOTA, D.C.",
  },
  {
    CONCESIONARIO: "MADIAUTOS",
    DEPARTAMENTO: "BOGOTA",
    CIUDAD: "BOGOTÁ",
    VITRINA_EXPOSICION: "Morato",
    COD_VITRINA_EXPOSICION: "a17QC00000bL1JGYA0",
    NIT: "8000847285",
    ID_Dealers: "302089",
    ID_sucursales: "905649",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "11 BOGOTA",
    City: "BOGOTA, D.C.",
  },
  {
    CONCESIONARIO: "MOTOR HYUNDAI",
    DEPARTAMENTO: "NARIÑO",
    CIUDAD: "PASTO",
    VITRINA_EXPOSICION: "Motor Hyundai",
    COD_VITRINA_EXPOSICION: "a17QC00000bLFYXYA4",
    NIT: "9011372606",
    ID_Dealers: "302116",
    ID_sucursales: "905589",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "52 NARIÑO",
    City: "PASTO",
  },
  {
    CONCESIONARIO: "REINDUSTRIAS",
    DEPARTAMENTO: "TOLIMA",
    CIUDAD: "IBAGUE",
    VITRINA_EXPOSICION: "Ibague",
    COD_VITRINA_EXPOSICION: "a17QC00000aw6kkYAA",
    NIT: "8130071475",
    ID_Dealers: "302100",
    ID_sucursales: "905607",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "73 TOLIMA",
    City: "IBAGUE",
  },
  {
    CONCESIONARIO: "REINDUSTRIAS",
    DEPARTAMENTO: "HUILA",
    CIUDAD: "NEIVA",
    VITRINA_EXPOSICION: "Neiva",
    COD_VITRINA_EXPOSICION: "a17QC00000aw6kiYAA",
    NIT: "8130071475",
    ID_Dealers: "302100",
    ID_sucursales: "905605",
    TIPO_DE_VEHICULOS: "PARTICULARES",
    Department_Code_And_Name: "41 HUILA",
    City: "NEIVA",
  },
];

const CotizacionVehiculosComponent: React.FC<
  CotizacionVehiculosComponentProps
> = ({ vehiculo }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [formState, setFormState] = useState({
    nombre: "",
    apellido: "",
    correo: "",
    celular: "",
    cedula: "",
    ciudad: "",
    departamento: "",
    concesionario: "",
    sucursal: "",
    politicas1: false,
    politicas2: false,
  });
  const [departamentos, setDepartamentos] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [concesionarios, setConcesionarios] = useState([]);
  const [sucursal, setSucursal] = useState([]);
  const [modelos, setModelos] = useState([
    {
      nombremodelo: "IONIQ 5 EV",
      nombrefamilia: "IONIQ 5",
      internal: "IONIQ 5",
      idmodelo: "a1YQC000000FK4s2AG",
      idfamilia: "a1FQC000007vHGE2A2",
    },
    {
      nombremodelo: "TUCSON 2.0L",
      nombrefamilia: "TUCSON",
      Internal: "TUCSON",
      idmodelo: "a1YQC000000FL4A2AW",
      idfamilia: "a1FQC000007vHWM2A2",
    },
    {
      nombremodelo: "TUCSON HIBRIDA",
      nombrefamilia: "TUCSON Híbrida",
      Internal: "TUCSON HIBRIDA",
      idmodelo: "a1YQC000001AXN72AO",
      idfamilia: "a1FQC00000U0pFl2AJ",
    },
    {
      nombremodelo: "GRAND I10 1.2L",
      nombrefamilia: "GRAND I10",
      internal: "GRAND METRO SEDÁN",
      idmodelo: "a1YQC000000FKT42AO",
      idfamilia: "a1FQC000007vHco2AE",
    },
    {
      nombremodelo: "GRAND I10 HB 1.2L",
      nombrefamilia: "GRAND METRO HATCHBACK",
      internal: "GRAND METRO SEDÁN",
      idmodelo: "a1YQC0000010Fhd2AE",
      idfamilia: "a1FQC000007vHco2AE",
    },
    {
      nombremodelo: "CRETA 1.5L",
      nombrefamilia: "CRETA",
      internal: "CRETA",
      idmodelo: "a1YQC000000FLTx2AO",
      idfamilia: "a1FQC000007vHuX2AU",
    },
    {
      nombremodelo: "HB20 1.6L",
      nombrefamilia: "HB20",
      internal: "HB20 Hatchback",
      idmodelo: "a1YQC000000FKrG2AW",
      idfamilia: "a1FQC000007vI7R2AU",
    },
    {
      nombremodelo: "HB20S 1.6L",
      nombrefamilia: "HB20S",
      internal: "HB20 Sedán",
      idmodelo: "a1YQC000000FLIf2AO",
      idfamilia: "a1FQC000007vI932AE",
    },
    {
      nombremodelo: "KONA 2.0L",
      nombrefamilia: "KONA",
      internal: "KONA Gasolina",
      idmodelo: "a1YQC000000FLtl2AG",
      idfamilia: "a1FQC000007vIAf2AM",
    },
    {
      nombremodelo: "KONA 1.6L HEV",
      nombrefamilia: "KONA",
      internal: "KONA Híbrida",
      idmodelo: "a1YQC000000FLvN2AW",
      idfamilia: "a1FQC000007vIAf2AM",
    },
    {
      nombremodelo: "KONA EV",
      nombrefamilia: "KONA",
      internal: "KONA eléctrica",
      idmodelo: "a1YQC000000FM6f2AG",
      idfamilia: "a1FQC000007vIAf2AM",
    },
    {
      nombremodelo: "KONA 1.6L HEV",
      nombrefamilia: "KONA",
      internal: "KONA N Line",
      idmodelo: "a1YQC000000eQyH2AU",
      idfamilia: "a1FQC000007vIAf2AM",
    },
    {
      nombremodelo: "PALISADE 3.5L",
      nombrefamilia: "PALISADE",
      internal: "PALISADE",
      idmodelo: "a1YQC000000FM9t2AG",
      idfamilia: "a1FQC000007vIH72AM",
    },
    {
      nombremodelo: "STARIA 2.2L DIESEL",
      nombrefamilia: "STARIA",
      internal: "STARIA panel",
      idmodelo: "a1YQC000000FK9i2AG",
      idfamilia: "a1FQC000007vIIj2AM",
    },
    {
      nombremodelo: "STARIA 3.5L",
      nombrefamilia: "STARIA",
      internal: "STARIA lujo",
      idmodelo: "a1YQC000000FMD72AO",
      idfamilia: "a1FQC000007vIIj2AM",
    },
    {
      nombremodelo: "STARIA 2.2L DIESEL",
      nombrefamilia: "STARIA",
      internal: "STARIA pasajeros",
      idmodelo: "a1YQC000000FMEj2AO",
      idfamilia: "a1FQC000007vIIj2AM",
    },
    {
      nombremodelo: "VENUE 1.6L",
      nombrefamilia: "VENUE",
      internal: "VENUE",
      idmodelo: "a1YQC000000FMhl2AG",
      idfamilia: "a1FQC000007vILx2AM",
    },
    {
      nombremodelo: "SANTA FE 1.6T HEV",
      nombrefamilia: "SANTA FE HIBRIDA",
      internal: "SANTA FE",
      idmodelo: "a1YQC0000011VPN2A2",
      idfamilia: "a1FQC00000PcdoL2AR",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [utmParams, setUTMParams]: any = useState({});
  const [ipAddress, setIPAddress] = useState("");
  const [error, setError] = React.useState({
    nombre: "",
    apellido: "",
    correo: "",
    celular: "",
    cedula: "",
    ciudad: "",
    concesionario: "",
  });

  console.log(vehiculo);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#cotizacion") {
      const element = document.getElementById("cotizacion");
      if (element) {
        const offsetTop = element.offsetTop - 120;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, []);

  useEffect(() => {
    // Validar y obtener los parámetros UTM
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const utms: any = {};
      [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content",
      ].forEach((key) => {
        if (urlParams.has(key)) {
          utms[key] = urlParams.get(key);
        }
      });
      setUTMParams(utms);
      //console.log("UTM Parameters:", utms);
    } catch (error) {
      console.error("Error al procesar las UTM:", error);
    }

    // Obtener la IP del usuario
    const fetchIP = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json"); // Alternativa confiable
        if (!response.ok) {
          throw new Error(`Error HTTP: ${response.status}`);
        }
        const data = await response.json();
        setIPAddress(data.ip);
        //console.log("IP Address:", data.ip);
      } catch (error) {
        console.error("Error obteniendo la IP:", error);
      }
    };
    fetchIP();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    // Chequeo para checkbox
    const checked =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : undefined;

    setFormState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "ciudad") {
      fetchConcesionarios(value);
      setFormState((prevState) => ({
        ...prevState,
        departamento: concesionariosData?.find((e: any) => e.CIUDAD === value)
          ?.Department_Code_And_Name,
      }));
    } else if (name === "departamento") {
      fetchCiudades(value);
    } else if (name === "concesionario") {
      fetchSucursal(value);
    }
  };

  const fetchCiudades = async (departamento: string) => {
    try {
      const response = await fetch(
        `https://gateway.hyundaicolombia.co/provincias`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            region: departamento,
          }),
        }
      );
      const data = await response.json();
      //console.log("Ciudades: ", data.data);
      setCiudades(data.data); // Actualiza el estado con los concesionarios obtenidos
    } catch (error) {
      console.error("Error fetching concesionarios: ", error);
    }
  };

  const fetchConcesionarios = async (ciudad: string) => {
    try {
      setConcesionarios(
        concesionariosData?.filter((e: any) => e.CIUDAD === ciudad)
      ); // Actualiza el estado con los concesionarios obtenidos
    } catch (error) {
      console.error("Error fetching concesionarios: ", error);
    }
  };

  const fetchSucursal = async (concesionario: string) => {
    try {
      setSucursal(
        concesionariosData?.filter(
          (e: any) => e.CIUDAD === formState?.ciudad && e.NIT === concesionario
        )
      ); // Actualiza el estado con los concesionarios obtenidos
      setFormState((prevState) => ({
        ...prevState,
        sucursal: concesionariosData?.filter(
          (e: any) => e.CIUDAD === formState?.ciudad && e.NIT === concesionario
        )[0].COD_VITRINA_EXPOSICION,
      }));
    } catch (error) {
      console.error("Error fetching concesionarios: ", error);
    }
  };

  const sendCotizacion = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://gateway.hyundaicolombia.co/create-lead`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            {
              marca: "Hyundai_Colombia",
              origen: "Web marca",
              nombre: formState.nombre,
              numeroDocumento: formState.cedula,
              lastName: formState.apellido,
              email: formState.correo,
              telefono: formState.celular,
              modelo: modelos.find(
                (e: any) =>
                  (e?.internal || e?.Internal)?.toLowerCase() ===
                  vehiculo?.title?.toLowerCase()
              )?.idmodelo,
              familia: modelos.find(
                (e: any) =>
                  (e?.internal || e?.Internal)?.toLowerCase() ===
                  vehiculo?.title?.toLowerCase()
              )?.idfamilia,
              concesionario: formState.concesionario,
              exposicion: formState.sucursal,
              region: formState.departamento,
              comuna: concesionariosData?.filter(
                (e: any) => e.CIUDAD === formState.ciudad
              )[0].City,
              provincia: concesionariosData?.filter(
                (e: any) => e.CIUDAD === formState.ciudad
              )[0].City,
              tipoConsulta: "VT",
              areaConsulta: "VT_Cotización",
              utm_campaign: utmParams.utm_campaign,
              utm_medium: utmParams.utm_medium,
              utm_source: utmParams.utm_source,
              gdprMarca: true,
              tratamientoDatos: true,
              legalTratamientoDatos:
                "Acepto la política de tratamiento de datos ",
              LegalTransferenciaDatos:
                "Autorizo la transferencia y transmisión nacional e internacional de mis datos para las finalidades de publicidad personalizada y Perfilación de clientes a  Hyundai, las compañías de su grupo y las que hacen parte de su cadena de producción, operación y comercialización. ",
              legalComunicaciones:
                "Acepto recibir ofertas, promociones, invitaciones y publicidad en general de  Hyundai, de la red de Concesionarios Autorizados a nivel nacional y/o sus aliados comerciales.",
              ip: ipAddress,
              gdprConcesionario: true,
              gdprDealer: true,
              suborigin: "233",
            },
          ]),
        }
      );
      const data = await response.json();
      setLoading(false);
      setResult(true);
      //console.log("Send Cotizacion: ", data);
      sendFormMultiQuotes({
        data: {
          user_name: formState.nombre,
          user_cc: formState.cedula,
          user_lastname: formState.apellido,
          user_email: formState.correo,
          user_phone: formState.celular,
          vehicle_model: modelos.find(
            (e: any) =>
              (e?.internal || e?.Internal)?.toLowerCase() ===
              vehiculo?.title?.toLowerCase()
          )?.idmodelo,
          vehicle_family: modelos.find(
            (e: any) =>
              (e?.internal || e?.Internal)?.toLowerCase() ===
              vehiculo?.title?.toLowerCase()
          )?.idfamilia,
          concessionaire: formState.concesionario,
          branch_office: formState.sucursal,
          region: formState.departamento,
          commune: concesionariosData?.filter(
            (e: any) => e.CIUDAD === formState.ciudad
          )[0].City,
          province: concesionariosData?.filter(
            (e: any) => e.CIUDAD === formState.ciudad
          )[0].City,
          quote_area: "VT_Cotización",
          utm_campaign: utmParams.utm_campaign,
          utm_medium: utmParams.utm_medium,
          utm_source: utmParams.utm_source,
          user_ip: ipAddress,
          sf_state: data?.success ? `${data?.success}` : "false",
          model: modelos.find(
            (e: any) =>
              (e?.internal || e?.Internal)?.toLowerCase() ===
              vehiculo?.title?.toLowerCase()
          )?.nombremodelo,
          family: modelos.find(
            (e: any) =>
              (e?.internal || e?.Internal)?.toLowerCase() ===
              vehiculo?.title?.toLowerCase()
          )?.nombrefamilia,
          concession: concesionariosData?.filter(
            (e: any) =>
              e.NIT === formState.concesionario && e.CIUDAD === formState.ciudad
          )[0].CONCESIONARIO,
          exposition_cod: formState.sucursal,
          exposition: concesionariosData.find(
            (e: any) =>
              e.NIT === formState.concesionario && e.CIUDAD === formState.ciudad
          )?.["VITRINA (EXPOSICIÓN)"],
        },
      }).then(function (res: any) {});
    } catch (error) {
      console.error("Error fetching concesionarios: ", error);
      setLoading(false);
    }
  };

  const handleReset = () => {
    setFormState({
      nombre: "",
      apellido: "",
      correo: "",
      celular: "",
      cedula: "",
      ciudad: "",
      departamento: "",
      concesionario: "",
      sucursal: "",
      politicas1: false,
      politicas2: false,
    });
    setCiudades([]);
    setConcesionarios([]);
    setSucursal([]);
    setLoading(false);
    setResult(false);
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const areAllFieldsFilled = Object.entries(formState).every(([key, value]) => {
    // Función auxiliar para validar que no sea una secuencia repetitiva o consecutiva
    const isSequential = (input: string): boolean => {
      const ascending = "0123456789";
      const descending = "9876543210";
      return (
        ascending.includes(input) ||
        descending.includes(input) ||
        /^(\d)\1+$/.test(input)
      );
    };

    if (typeof value === "boolean") {
      if (key === "politicas1") {
        return value; // Asegurarse de que el campo politicas1 sea verdadero
      }
      return true; // Ignorar otros campos booleanos como politicas2
    }

    if (typeof value === "string") {
      switch (key) {
        case "nombre":
        case "apellido":
          return (
            value.trim() !== "" &&
            /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(value.trim())
          );
        case "correo":
          return value.trim() !== "" && /\S+@\S+\.\S+/.test(value.trim());
        case "celular":
          return (
            value.trim() !== "" &&
            /^[0-9]{10}$/.test(value.trim()) &&
            !isSequential(value.trim())
          );
        case "cedula":
          return (
            value.trim() !== "" &&
            /^\d+$/.test(value.trim()) &&
            value.trim().length >= 8 &&
            value.trim().length <= 10 &&
            !isSequential(value.trim())
          );
        case "ciudad":
        case "concesionario":
          return value.trim() !== ""; // Solo verificar que no esté vacío
        default:
          return true; // Si hay campos que no necesitan validación específica
      }
    }

    return false; // Retorna falso para tipos inesperados
  });

  const validateField = (name: string, value: string): string => {
    // Función auxiliar para validar que no sea una secuencia repetitiva o consecutiva
    const isSequential = (input: string): boolean => {
      const ascending = "0123456789";
      const descending = "9876543210";
      return (
        ascending.includes(input) ||
        descending.includes(input) ||
        /^(\d)\1+$/.test(input)
      );
    };

    switch (name) {
      case "nombre":
        if (value.trim() === "") return "El nombre es requerido";
        if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(value.trim()))
          return "El nombre solo debe contener letras";
        return "";

      case "apellido":
        if (value.trim() === "") return "El apellido es requerido";
        if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(value.trim()))
          return "El apellido solo debe contener letras";
        return "";

      case "correo":
        if (value.trim() === "") return "El correo es requerido";
        if (!/\S+@\S+\.\S+/.test(value.trim())) return "El correo no es válido";
        return "";

      case "celular":
        if (value.trim() === "") return "El celular es requerido";
        if (!/^\d+$/.test(value.trim()))
          return "El celular debe contener solo números";
        if (value.trim().length !== 10)
          return "El celular debe tener exactamente 10 dígitos";
        if (isSequential(value.trim()))
          return "El celular no debe ser secuencial, repetitivo ni solo ceros";
        return "";

      case "cedula":
        if (value.trim() === "") return "La cédula es requerida";
        if (!/^\d+$/.test(value.trim()))
          return "La cédula debe contener solo números";
        if (value.trim().length < 8 || value.trim().length > 10)
          return "La cédula debe tener entre 8 y 10 dígitos";
        if (isSequential(value.trim()))
          return "La cédula no debe ser secuencial, repetitiva ni solo ceros";
        return "";

      case "ciudad":
        if (value.trim() === "") return "La ciudad es requerida";
        return "";

      case "concesionario":
        if (value.trim() === "") return "El concesionario es requerido";
        return "";

      default:
        return "";
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    // Clonar el estado actual de errores
    const newErrors = { ...error };

    // Validar el campo específico
    const errorMessage = validateField(name, value);
    newErrors[name as keyof typeof error] = errorMessage;

    // Actualizar el estado de errores
    setError(newErrors);
  };

  const validateForm = () => {
    const newErrors: typeof error = {
      nombre: validateField("nombre", formState.nombre),
      apellido: validateField("apellido", formState.apellido),
      correo: validateField("correo", formState.correo),
      celular: validateField("celular", formState.celular),
      cedula: validateField("cedula", formState.cedula),
      ciudad: validateField("ciudad", formState.ciudad),
      concesionario: validateField("concesionario", formState.concesionario),
    };

    // Actualizar el estado de errores con todos los campos validados
    setError(newErrors);

    // Retornar si el formulario es válido
    return Object.values(newErrors).every((error) => error === "");
  };

  return (
    <div
      className={`container-cotizacion-multiple-component ${
        isVisible ? "visible" : ""
      }`}
    >
      <div className="title-container-cotizacion-multiple-component">
        {loading || result
          ? "Ya estás más cerca de tener tu nuevo Hyundai"
          : `Cotiza tu ${vehiculo?.name}`}
      </div>
      {!loading && !result ? (
        <>
          <div className="left-container-cotizacion-multiple-component">
            <div className="descripcion-left-container-cotizacion-multiple-component">
              Recibe una cotización detallada y agéndate a un Test drive
              ahorapara conocer todas las oportunidades que éste vehículo puede
              ofrecerte.
            </div>
            <div className="subtitle-left-container-cotizacion-multiple-component">
              Estás cotizando tu Hyundai
            </div>
            <div className="vehiculos-left-container-cotizacion-multiple-component">
              <div className="asset-vehiculos-left-container-cotizacion-multiple-component">
                <img
                  src={`https://manager.hyundaicolombia.co/${vehiculo?.versions[0]?.image?.url}`}
                  alt={
                    vehiculo?.name
                      ? `Imagen del vehículo ${vehiculo.name}`
                      : "Imagen de vehículo Hyundai"
                  }
                />
              </div>
              <div className="info-vehiculos-left-container-cotizacion-multiple-component">
                <div className="title-info-vehiculos-left-container-cotizacion-multiple-component">
                  {vehiculo?.name}
                </div>
                <div className="subtitle-info-vehiculos-left-container-cotizacion-multiple-component">
                  Disponible desde
                </div>
                <div className="precio-info-vehiculos-left-container-cotizacion-multiple-component">
                  {formatCurrency(vehiculo?.versions[0]?.price)} *
                </div>
              </div>
            </div>
            <div className="condiciones-left-container-cotizacion-multiple-component">
              *Los valores y precios publicados son de referencia y pueden
              variar sin previo aviso.
            </div>
            <div className="condiciones-left-container-cotizacion-multiple-component">
              *Las imágenes son ilustrativas, algunas características de los
              vehículos de las imágenes pueden variar.
            </div>
          </div>
          <div className="right-container-cotizacion-multiple-component">
            <div className="form-right-container-cotizacion-multiple-component form">
              <div className="input-form-right-container-cotizacion-multiple-component">
                <label htmlFor="nombre">Nombre*</label>
                <input
                  type="text"
                  name="nombre"
                  value={formState.nombre}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {error.nombre && (
                  <span className="error">{"Nombre es requerido"}</span>
                )}
              </div>
              <div className="input-form-right-container-cotizacion-multiple-component">
                <label htmlFor="apellido">Apellido*</label>
                <input
                  type="text"
                  name="apellido"
                  value={formState.apellido}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {error.apellido && (
                  <span className="error">{"Apellido es requerido"}</span>
                )}
              </div>
              <div className="input-form-right-container-cotizacion-multiple-component">
                <label htmlFor="correo">Correo*</label>
                <input
                  type="text"
                  name="correo"
                  value={formState.correo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {error.correo && (
                  <span className="error">{"Correo no es válido"}</span>
                )}
              </div>
              <div className="input-form-right-container-cotizacion-multiple-component">
                <label htmlFor="celular">Celular*</label>
                <input
                  type="text"
                  name="celular"
                  value={formState.celular}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {error.celular && (
                  <span className="error">{"Celular no es válido"}</span>
                )}
              </div>
              <div className="input-form-right-container-cotizacion-multiple-component">
                <label htmlFor="cedula">Cédula*</label>
                <input
                  type="text"
                  name="cedula"
                  value={formState.cedula}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {error.cedula && (
                  <span className="error">{"Cédula no es válida"}</span>
                )}
              </div>
              <div className="input-form-right-container-cotizacion-multiple-component">
                <label htmlFor="ciudad">Ciudad*</label>
                <select
                  name="ciudad"
                  value={formState.ciudad}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Selecciona</option>
                  {Array.from(
                    new Set(concesionariosData.map((item: any) => item.CIUDAD))
                  ).map((ciudad: any) => (
                    <option key={ciudad} value={ciudad}>
                      {ciudad}
                    </option>
                  ))}
                </select>
                {error.ciudad && (
                  <span className="error">{"Ciudad es requerida"}</span>
                )}
              </div>
              <div className="input-form-right-container-cotizacion-multiple-component">
                <label htmlFor="concesionario">Concesionario*</label>
                <select
                  name="concesionario"
                  value={formState.concesionario}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Selecciona</option>
                  {concesionarios?.map((concesionario: any) => (
                    <option key={concesionario.NIT} value={concesionario.NIT}>
                      {concesionario.CONCESIONARIO}
                    </option>
                  ))}
                </select>
                {error.concesionario && (
                  <span className="error">{"Concesionario es requerido"}</span>
                )}
              </div>
            </div>

            <div className="politicas-right-container-cotizacion-multiple-component">
              <div>
                <input
                  type="checkbox"
                  name="politicas1"
                  checked={formState.politicas1}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="">
                  Acepto política de datos, términos y condiciones.
                </label>
              </div>
            </div>
            <div className="politicas-right-container-cotizacion-multiple-component">
              <div>
                <input
                  type="checkbox"
                  name="politicas2"
                  checked={formState.politicas2}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="">
                  Autorizo la transferencia y transmisión de mis datos para las
                  finalidades de publicidad a las compañías de su grupo.
                </label>
              </div>
            </div>
            <div className="text-politicas-right-container-cotizacion-multiple-component">
              Estamos comprometidos con tu privacidad, por ello, en Hyundai
              Colombia S.A.S. junto con nuestros aliados oficiales te aseguramos
              que utilizaremos la información que nos proporcionas para
              comunicarnos contigo acerca de nuestros productos, servicios,
              ofertas, invitaciones, encuestas, entre otras finalidades y
              contenidos relevantes para ti. Puedes darte de baja de estas
              comunicaciones en cualquier momento. Para obtener más información
              consulta nuestra
            </div>
            <div
              onClick={() => sendCotizacion()}
              className={`btn-right-container-cotizacion-multiple-component ${
                areAllFieldsFilled ? "active" : ""
              }`}
            >
              Cotizar
            </div>
          </div>
        </>
      ) : loading && !result ? (
        <div className="spinner">
          <div className="circle"></div>
        </div>
      ) : (
        <div className="left-container-cotizacion-multiple-component result">
          <div className="descripcion-left-container-cotizacion-multiple-component">
            Gracias por escogernos. Pronto un asesor se contactará contigo.
          </div>
          <div className="vehiculos-left-container-cotizacion-multiple-component">
            <div className="asset-vehiculos-left-container-cotizacion-multiple-component">
              <img
                src={`https://manager.hyundaicolombia.co/${vehiculo?.versions[0]?.image?.url}`}
                alt={vehiculo?.name || "Imagen del vehículo"}
              />
            </div>
            <div className="info-vehiculos-left-container-cotizacion-multiple-component">
              <div className="title-info-vehiculos-left-container-cotizacion-multiple-component">
                {vehiculo?.name}
              </div>
              <div className="subtitle-info-vehiculos-left-container-cotizacion-multiple-component">
                Disponible desde
              </div>
              <div className="precio-info-vehiculos-left-container-cotizacion-multiple-component">
                {formatCurrency(vehiculo?.versions[0]?.price)}
              </div>
            </div>
          </div>

          <div className="condiciones-left-container-cotizacion-multiple-component">
            *Los valores y precios publicados son de referencia y pueden variar
            sin previo aviso.
          </div>
          <div className="condiciones-left-container-cotizacion-multiple-component">
            *Las imágenes son ilustrativas, algunas características de los
            vehículos de las imágenes pueden variar.
          </div>
          <div
            className="listo-left-container-cotizacion-multiple-component"
            onClick={() => handleReset()}
          >
            Listo
          </div>
        </div>
      )}
    </div>
  );
};
export default CotizacionVehiculosComponent;
