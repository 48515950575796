/*eslint-disable */
import React, { useEffect, useState } from "react";
import MenuLayouts from "../layouts/menu.layout";
import FooterLayouts from "../layouts/footer.layout";
import BtnFloatingLayout from "../layouts/btn_flotante.layout";
import BannerComponent from "../components/multi_cotizacion/banner.component";
import DescriptionComponent from "../components/multi_cotizacion/description.component";
import CotizacionVehiculosComponent from "../components/multi_cotizacion/cotizacion-vehiculos.component";
import VehiculosComponent from "../components/multi_cotizacion/vehiculos.component";
import {
  multicotizador,
  vehiclesSimulator,
} from "../components/conexion/vehicles.conexion";

const MultiCotizacionPage: React.FC = () => {
  const [visibleHeight, setVisibleHeight] = useState(0);
  const [vehiculosData, setVehiculosData]: any = useState([]);
  const [vehiculosSelected, setVehiculosSelected]: any = useState(null);
  const [multicotizadorData, setMulticotizadorData]: any = useState([]);

  useEffect(() => {
    vehiclesSimulator().then(function (res: any) {
      let data: any = res?.data?.data;
      setVehiculosData(data);
      setVehiculosSelected(data[0]);
      /* setModelo(vehiculo !== undefined && vehiculo !== "" ? vehiculo : data[0].slug)
            setVersion(vehiculo !== undefined && vehiculo !== "" ? data?.find((item: any) => item.slug ===  vehiculo)?.versions[0]?.name : data[0]?.versions[0]?.name) */
    });
    multicotizador().then(function (res: any) {
      setMulticotizadorData(res?.data?.data);
    });
  }, []);

  return (
    <>
      <MenuLayouts
        className="home-page"
        active="somos"
        setVisibleHeight={setVisibleHeight}
        visibleHeight={visibleHeight}
      />
      <div
        className="home-page" /* style={{backgroundColor: "#F6F3F2", height: "100vh"}} */
      >
        <h1
          style={{ position: "absolute", opacity: "0", pointerEvents: "none" }}
        >
          Multicotización Hyundai Colombia - Compara y Encuentra tu Mejor Opción
        </h1>

        <div className="home-page-mascara"></div>
        <BannerComponent multicotizadorData={multicotizadorData} />
        <DescriptionComponent multicotizadorData={multicotizadorData} />

        <div className="cotizacion-multiple-component" id="cotizacion">
          <VehiculosComponent
            vehiculosData={vehiculosData}
            setVehiculosSelected={setVehiculosSelected}
          />
          {vehiculosSelected !== null ? (
            <CotizacionVehiculosComponent vehiculo={vehiculosSelected} />
          ) : null}
        </div>
      </div>
      <BtnFloatingLayout reserva="https://reservas.hyundaicolombia.co/" />
      <FooterLayouts />
    </>
  );
};

export default MultiCotizacionPage;
