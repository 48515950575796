import React, { useEffect, Suspense, lazy, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./layouts/scroll.layout";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PageViewTracker from "./components/conexion/pageViewTracker";
import MultiCotizacionPage from "./pages/multi_cotizacion.page";
import { MetaData } from "./components/conexion/home.conexion";

// Lazy loading de páginas
const HomePage = lazy(() => import("./pages/home.page"));
const VehiculosPage = lazy(() => import("./pages/vehiculos.page"));
const VehiculosDetailPage = lazy(() => import("./pages/vehiculos-detail.page"));
const SimuladorPage = lazy(() => import("./pages/simulador.page"));
const ConcesionariosPage = lazy(() => import("./pages/concesionarios.page"));
const PosventaPage = lazy(() => import("./pages/posventa.page"));
const NoticiasPage = lazy(() => import("./pages/noticias.page"));
const NoticiasNormalPage = lazy(() => import("./pages/noticia_normal.page"));
const QuienesSomosPage = lazy(() => import("./pages/quienes-somos.page"));
const PQRPage = lazy(() => import("./pages/pqr.page"));
const CitaTallerPage = lazy(() => import("./pages/cita_taller.page"));
const AstaraPage = lazy(() => import("./pages/astara.page"));
const DestacadasPage = lazy(() => import("./pages/destacadas.page"));
const PoliticasTerminosCondicionesPage = lazy(
  () => import("./pages/politicas_terminos_condiciones.page")
);
const PoliticasTerminosReservasPage = lazy(
  () => import("./pages/politicas_terminos_reservas.page")
);
const PoliticasPaginaPage = lazy(() => import("./pages/politicas_pagina.page"));
const PoliticasAvisoPrivacidadPage = lazy(
  () => import("./pages/politicas_aviso_privacidad.page")
);
const PoliticasProteccionDatosPage = lazy(
  () => import("./pages/politicas_proteccion_datos.page")
);
const PoliticasCalidadPage = lazy(
  () => import("./pages/politicas_calidad.page")
);
const PoliticasCookiesPage = lazy(
  () => import("./pages/politicas_cookies.page")
);

const NotFound: React.FC = () => (
  <div>
    <header className="App-header">
      <h1>404</h1>
      <p>Lo sentimos, la página que buscas no existe.</p>
      <Link to="/" className="App-link">
        Volver al inicio
      </Link>
    </header>
  </div>
);

// Componente para manejar eventos de GTM en cada cambio de ruta
const GTMHandler: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: location.pathname + location.search,
        pageTitle: document.title,
      },
    });
  }, [location]);

  return null;
};

// **Función para formatear el título**
const formatTitle = (pathname: string): string => {
  if (pathname === "/") {
    return "Hyundai Colombia"; // Página principal
  }

  return pathname
    .split("/") // Divide la ruta en partes
    .filter(segment => segment.length > 0) // Elimina segmentos vacíos
    .map(segment =>
      segment
        .replace(/-/g, " ") // Reemplaza los guiones por espacios
        .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitaliza cada palabra
    )
    .join(" | ") + " | Hyundai Colombia"; // Une con " | " y agrega la marca
};

// **Componente para actualizar dinámicamente el título de la página**
const DynamicHelmet = () => {
  const location = useLocation();
  const [title, setTitle] = useState("Hyundai Colombia");

  useEffect(() => {
    setTitle(formatTitle(location.pathname));
  }, [location]);

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

// **Componente para manejar la carga del banner con useLocation**
const FetchMetaData: React.FC<{
  setMeta: React.Dispatch<React.SetStateAction<any>>;
}> = ({ setMeta }) => {
  const location = useLocation();

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const res = await MetaData(location.pathname);
        console.log("Metadata cargada:", res.data.data[0]);
        setMeta(res.data.data[0]);
      } catch (error) {
        console.error("Error cargando metadatos:", error);
      }
    };

    fetchMetaData();
  }, [location.pathname]); // 👈 Se ejecuta cada vez que cambia la URL

  return null; // Este componente no renderiza nada
};

const App: React.FC = () => {
  const [meta, setMeta]: any = useState(null);

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-KPL7ZXZ" });
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <GTMHandler />
        <DynamicHelmet /> {/* 👈 Nuevo componente para actualizar el título */}
        <PageViewTracker />

        <div className="App">
          {/* Suspense para manejar lazy loading */}
          <Suspense
            fallback={
              <div className="skeleton-container">
                <div className="skeleton-box"></div>
                <div className="skeleton-text"></div>
              </div>
            }
          >
            <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/vehiculos" element={<VehiculosPage />} />
            <Route
              path="/vehiculos/:vehiculo"
              element={<VehiculosDetailPage />}
            />
            <Route
              path="/vehiculos/:vehiculo/:section"
              element={<VehiculosDetailPage />}
            />
            <Route path="/posventa" element={<PosventaPage />} />
            <Route path="/posventa/:servicio" element={<PosventaPage />} />
            <Route path="/posventa/:servicio/:vehicle" element={<PosventaPage />} />
            <Route
              path="/simulador-de-cuotas/:vehiculo?"
              element={<SimuladorPage />}
            />
            <Route path="/noticias" element={<NoticiasPage />} />
            <Route path="/noticias/:category" element={<NoticiasPage />} />
            <Route
              path="/noticias/:category/:news"
              element={<NoticiasNormalPage />}
            />
            <Route path="/concesionarios" element={<ConcesionariosPage />} />
            <Route path="/quienes-somos" element={<QuienesSomosPage />} />
            <Route path="/astara" element={<AstaraPage />} />
            <Route path="/contactanos" element={<PQRPage />} />
            <Route path="/cita-taller" element={<CitaTallerPage />} />
            <Route path="/fichas-seguridad" element={<DestacadasPage />} />
            <Route path="/cotizar" element={<MultiCotizacionPage />} />
            <Route
              path="/terminos-condiciones"
              element={<PoliticasTerminosCondicionesPage />}
            />
            <Route
              path="/terminos-reservas-online"
              element={<PoliticasTerminosReservasPage />}
            />
            <Route path="/politica-pagina" element={<PoliticasPaginaPage />} />
            <Route
              path="/avisos-privacidad"
              element={<PoliticasAvisoPrivacidadPage />}
            />
            <Route
              path="/politica-proteccion-datos"
              element={<PoliticasProteccionDatosPage />}
            />
            <Route
              path="/politica-calidad"
              element={<PoliticasCalidadPage />}
            />
            <Route
              path="/politica-cookies"
              element={<PoliticasCookiesPage />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>

          </Suspense>
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;
