import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare const window: any;

interface DataLayerCompCore {
  event: string;
  comp_core: {
    site_brand: string;
    site_product_condition: string;
    site_regional_hub: string;
    site_country: string;
    site_language: string;
    site_type: string;
    site_domain: string;
    business_unit: string;
    page_category: string;
    page_title: string;
    page_url: string;
    user_agent: string;
    user_name_sha: string | null;
    user_name: string | null;
    user_email_sha: string | null;
    user_email: string | null;
    user_phone_sha: string | null;
    user_phone: string | null;
    user_id: string;
    sf_contact_id: string;
    user_id_cookie: string;
    device_id: string;
    device_type: string;
    operating_system: string;
    user_business: string;
    user_type: string;
    user_recency: string;
    user_status: string;
    browser_language: string;
    campaign: string;
    medium: string;
    source: string;
    referrer: string;
    previous_page: string;
  };
}

const PageViewTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const getUserAgent = () => navigator.userAgent;
    const getDeviceType = () => {
      const ua = navigator.userAgent.toLowerCase();
      if (/mobile|android|iphone|ipad|tablet/.test(ua)) return 'Mobile';
      if (/mac|windows|linux/.test(ua)) return 'Desktop';
      return 'Unknown';
    };
    const getOperatingSystem = () => {
      const ua = navigator.userAgent.toLowerCase();
      if (/windows/.test(ua)) return 'Windows';
      if (/mac/.test(ua)) return 'MacOS';
      if (/linux/.test(ua)) return 'Linux';
      if (/android/.test(ua)) return 'Android';
      if (/iphone|ipad/.test(ua)) return 'iOS';
      return 'Unknown';
    };
    const getBrowserLanguage = () => navigator.language || navigator.languages[0];
    const getReferrer = () => document.referrer;

    // Obtener valores dinámicos
    const dataLayerEvent: DataLayerCompCore = {
      event: 'page_view',
      comp_core: {
        site_brand: 'hyundai', // Personaliza este valor
        site_product_condition: 'new', // Personaliza este valor
        site_regional_hub: 'latam hub 2', // Personaliza este valor
        site_country: 'colombia', // Personaliza este valor
        site_language: getBrowserLanguage(),
        site_type: 'main website', // Personaliza este valor
        site_domain: window.location.hostname,
        business_unit: 'distribution', // Personaliza este valor
        page_category: 'lead form', // Categoría basada en la URL
        page_title: document.title,
        page_url: window.location.href,
        user_agent: getUserAgent(),
        user_name_sha: null, // Puedes implementar una función para generar SHA si tienes datos
        user_name: null, // Llenar si el usuario está autenticado
        user_email_sha: null, // Similar a user_name_sha
        user_email: null,
        user_phone_sha: null,
        user_phone: null,
        user_id: localStorage.getItem('userId') || 'anonymous', // Ejemplo de obtener el ID de usuario
        sf_contact_id: sessionStorage.getItem('sfContactId') || 'none', // Ejemplo de obtener ID de Salesforce
        user_id_cookie: document.cookie
          .split('; ')
          .find((row) => row.startsWith('user_id='))?.split('=')[1] || 'none',
        device_id: 'device-' + Math.random().toString(36).substring(2, 15), // Ejemplo de generar un ID único
        device_type: getDeviceType(),
        operating_system: getOperatingSystem(),
        user_business: 'b2c', // Personaliza este valor
        user_type: 'new', // Ejemplo, puede ser 'guest' o 'logged_in'
        user_recency: 'new', // Ejemplo, puede ser 'new' o 'returning'
        user_status: 'not logged', // Ejemplo, puede ser 'active' o 'inactive'
        browser_language: getBrowserLanguage(),
        campaign: new URLSearchParams(window.location.search).get('utm_campaign') || 'none',
        medium: new URLSearchParams(window.location.search).get('utm_medium') || 'none',
        source: new URLSearchParams(window.location.search).get('utm_source') || 'none',
        referrer: getReferrer(),
        previous_page: sessionStorage.getItem('previous_page') || 'none',
      },
    };

    // Guardar la página anterior en sessionStorage
    sessionStorage.setItem('previous_page', location.pathname);

    // Empujar al dataLayer
    if (window.dataLayer) {
      window.dataLayer.push(dataLayerEvent);
    }
  }, [location]);

  return null; // Este componente no renderiza nada
};

export default PageViewTracker;
